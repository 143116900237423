import { useEffect } from "react";

export const PostAffilateClickTrackingCode = ({ accountId }) => {
	useEffect(() => {
		let isSubscribed = true;

		const loadScript = () => {
			if (document.getElementById("pap_x2s6df8d")) {
				return;
			}

			const papScriptHeader = document.createElement("script");
			papScriptHeader.src =
				"https://netspace.postaffiliatepro.com/scripts/ujdwqg89myw";
			papScriptHeader.id = "pap_x2s6df8d";
			papScriptHeader.type = "text/javascript";

			papScriptHeader.onerror = function () {
				// eslint-disable-next-line no-console
				console.error(
					"Nie udało się załadować skryptu PostAffiliatePro"
				);
			};

			papScriptHeader.onload = function () {
				if (!isSubscribed) return;

				try {
					if (!window.PostAffTracker) {
						throw new Error("PostAffTracker nie jest zdefiniowany");
					}
					window.PostAffTracker.setAccountId(accountId);
					window.PostAffTracker.track();
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error(
						"PostAffiliatePro tracking error:",
						err.message
					);
				}
			};

			document.body.appendChild(papScriptHeader);
		};

		loadScript();

		return () => {
			isSubscribed = false;
			const existingScript = document.getElementById("pap_x2s6df8d");
			if (existingScript) {
				document.body.removeChild(existingScript);
			}
		};
	}, []);

	return null;
};
