// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const Container = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 100%;

	@media (min-width: ${breakpoints.phone}px) {
		max-width: ${breakpoints.phone - 1}px;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		max-width: ${breakpoints.tablet - 1}px;
	}

	@media (min-width: ${breakpoints.desktop}px) {
		max-width: ${breakpoints.desktop - 1}px;
	}

	@media (min-width: ${breakpoints.giant}px) {
		max-width: ${breakpoints.giant - 1}px;
	}

	@media (min-width: ${breakpoints.veryGiant}px) {
		max-width: ${breakpoints.veryGiant}px;
	}
`;

// #####################################################
