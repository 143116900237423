// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
// import Video from "components/base/Video";

// #####################################################

export const StyledHeaderVideo = styled.video`
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-height: 100%;
	min-width: 100%;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

// #####################################################
