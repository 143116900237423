// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledFormControlPasswordShowContainer = styled("div")`
	border-top-right-radius: var(--dz-ref-border-radius-0-75);
	border-bottom-right-radius: var(--dz-ref-border-radius-0-75);
`;

// #####################################################
