// ** React Imports
import { useMemo } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Block from "components/layout/Block";

// ** Login Components
import { RecoverButtonStyled } from "containers/Landing/styles/RecoverButton.styled";

// #####################################################

const PasswordRecoverButton = ({ setActiveTab }) => {
	const { t } = useTranslation(["landing"]);

	const language = useSelector((state) => state.global.language);

	const recoverButtonText = useMemo(() => {
		const text = t("landing:login.goToRecover");
		return text;
	}, [language]);

	// #####################################################

	return (
		<Block flex justifyEnd mt={2}>
			<RecoverButtonStyled
				onMouseDown={() => {
					setActiveTab("recover");
				}}
				label={recoverButtonText}
			>
				{recoverButtonText}
			</RecoverButtonStyled>
		</Block>
	);
};

// #####################################################

export default PasswordRecoverButton;

// #####################################################
