import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useMemo } from "react";

export default function useQuery() {
	const location = useLocation() || {};
	return useMemo(
		() => queryString.parse(location.search) ?? {},
		[location.search]
	);
}
