import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { throttle } from "throttle-debounce";

const useScrollPosition = () => {
	const history = useHistory();
	const scrollPosition = useRef(0);

	let savedPosition = 0;
	if (typeof sessionStorage !== "undefined" && sessionStorage !== null) {
		savedPosition = sessionStorage.getItem("scrollPosition");
	}

	useEffect(() => {
		if (
			typeof sessionStorage === "undefined" &&
			sessionStorage === null &&
			typeof window === "undefined"
		) {
			return;
		}

		if (history.action === "POP" && savedPosition > 0) {
			setTimeout(() => {
				window.scrollTo(0, savedPosition);
			}, 0);
		}

		setTimeout(() => {
			sessionStorage.setItem("scrollPosition", 0);
		}, 1000);

		const handleScroll = () => {
			scrollPosition.current = window.scrollY;
		};
		const throttleFunc = throttle(300, handleScroll);
		window.addEventListener("scroll", throttleFunc);

		return () => {
			sessionStorage.setItem("scrollPosition", scrollPosition.current);
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
};

export default useScrollPosition;
