// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledHeaderOverlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;

	background: linear-gradient(
		to bottom,
		rgba(15, 80, 160, 0.3) 0%,
		rgba(8, 45, 89, 0.65) 100%
	);
`;

// #####################################################
