// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// ** Custom Components
import Section from "components/layout/Section";

// #####################################################

export const StyledSectionWelcome = styled(Section)`
	display: none;
	@media (max-width: ${breakpoints.desktop - 1}px) {
		display: block;
	}
`;

// #####################################################
