// ** Third Party Components
import styled, { css } from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledCol = styled.div`
	position: relative;
	width: 100%;
	min-height: 1px;

	${({ hiddenLgDown }) =>
		hiddenLgDown &&
		css`
			@media (max-width: ${breakpoints.desktop - 1}px) {
				display: none;
			}
		`}

	${({ sm }) =>
		sm &&
		css`
			@media (min-width: ${breakpoints.phone}px) {
				flex: 0 0 ${(sm / 12) * 100}%;
				max-width: ${(sm / 12) * 100}%;
			}
		`}

	${({ md }) =>
		md &&
		css`
			@media (min-width: ${breakpoints.tablet}px) {
				flex: 0 0 ${(md / 12) * 100}%;
				max-width: ${(md / 12) * 100}%;
			}
		`}

	${({ lg }) =>
		lg &&
		css`
			@media (min-width: ${breakpoints.desktop}px) {
				flex: 0 0 ${(lg / 12) * 100}%;
				max-width: ${(lg / 12) * 100}%;
			}
		`}

	${({ xl }) =>
		xl &&
		css`
			@media (min-width: ${breakpoints.giant}px) {
				flex: 0 0 ${(xl / 12) * 100}%;
				max-width: ${(xl / 12) * 100}%;
			}
		`}
`;

// #####################################################
