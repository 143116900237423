// ** React Imports
import { useMemo } from "react";

// ** Styled Components
import { StyledFormControlPasswordShowContainer } from "../containers/Login/styles/FormControlPasswordShowContainer.styled";
import { StyledFormControlPasswordShowButton } from "../containers/Login/styles/FormControlPasswordShowButton.styled";
import SolidEye from "components/icons/SolidEye";
import SolidEyeSlash from "components/icons/SolidEyeSlash";

// #####################################################

const ShowHidePasswordButton = ({
	passwordVisibility,
	setPasswordVisibility,
}) => {
	const handleShowPass = () => {
		setPasswordVisibility((prev) => !prev);
	};

	const iconStyle = useMemo(() => {
		return { verticalAlign: "-0.125em", height: "1em" };
	}, []);

	// #####################################################

	return (
		<StyledFormControlPasswordShowContainer>
			<StyledFormControlPasswordShowButton onClick={handleShowPass}>
				{passwordVisibility ? (
					<SolidEye style={iconStyle} />
				) : (
					<SolidEyeSlash style={iconStyle} />
				)}
			</StyledFormControlPasswordShowButton>
		</StyledFormControlPasswordShowContainer>
	);
};

// #####################################################

export default ShowHidePasswordButton;

// #####################################################
