// ** React Imports
import { memo, useMemo } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Utils & Helpers
import staticFileHelper from "helpers/staticFileHelper";

// ** Styled Components
import { StyledHeaderOverlay } from "../styles/HeaderOverlay.styled";
import { StyledHeaderVideo } from "../styles/HeaderVideo.styled";

// #####################################################

const SectionBackground = () => {
	const randomVideoIndex = useSelector(
		(state) => state.mainPages.landing.data.randomVideoIndex
	);

	const randomVideoIndexFallback = useMemo(() => {
		return Math.floor(Math.random() * 3) + 1;
	}, []);

	const randomVideoIndexFinal = randomVideoIndex || randomVideoIndexFallback;

	// ** Hook useMemo - Dane Filmu Na Podstawie Wylosowanego ID
	const videoData = useMemo(() => {
		return {
			poster: staticFileHelper(
				`/img/mov/poster_${randomVideoIndexFinal}.jpg`,
				"videos"
			),
			source: staticFileHelper(
				`/img/mov/datezone_${randomVideoIndexFinal}.mp4`,
				"videos"
			),
			type: "video/mp4",
		};
	}, [randomVideoIndexFinal]);

	// #####################################################

	return (
		<>
			<StyledHeaderOverlay />
			{videoData && (
				<StyledHeaderVideo
					loop
					muted
					autoPlay
					playsInline
					noControls
					poster={videoData.poster}
				>
					<source src={videoData.source} type={videoData.type} />
				</StyledHeaderVideo>
			)}
		</>
	);
};

// #####################################################

export default memo(SectionBackground);

// #####################################################
