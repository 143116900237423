// ** React Imports
import { useCallback, useMemo } from "react";

// ** Configuration
import config from "configuration";

// #####################################################

const useOpenGraphMetaTags = ({
	customTitle,
	customDescription,
	customUrl,
	customImage,
} = {}) => {
	const metaOgTags = useMemo(() => {
		return [
			customTitle
				? {
						name: "title",
						content:
							customTitle?.length > 60
								? `${customTitle?.substring(0, 57)}...`
								: customTitle,
				  }
				: config.app.head.meta.title,
			customDescription
				? {
						name: "description",
						content:
							customDescription?.length > 60
								? `${customDescription?.substring(0, 57)}...`
								: customDescription,
				  }
				: config.app.head.meta.description &&
				  config.app.head.meta.description?.length > 60
				? `${config.app.head.meta.description?.substring(0, 57)}...`
				: config.app.head.meta.description,
			customUrl
				? { name: "url", content: customUrl }
				: config.app.head.meta.url,
			customImage
				? {
						name: "image",
						content: customImage,
				  }
				: config.app.head.meta.image,
			config.app.head.meta.type,
		];
	}, [customTitle, customDescription, customUrl, customImage]);

	const metaOgTagsCallback = useCallback(() => {
		return metaOgTags.map((tagItem) => (
			<meta
				key={tagItem.name}
				property={`og:${tagItem.name}`}
				content={tagItem.content}
			/>
		));
	}, [metaOgTags]);

	// #####################################################

	return metaOgTagsCallback();
};

// #####################################################

export default useOpenGraphMetaTags;

// #####################################################
